
export default {
  name: 'MoleculeImageSliderWithThumbnails',

  props: {
    sliderData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    typeOf: {
      required: true,
      type: String,
      default: function () {
        return 'image'
      }
    }
  },
  data() {
    return {
      instance: null,
      options: {
        perPage: 1,
        type: 'slide',
        pagination: false,
        drag: this.sliderData.length > 1,
        arrows: false
      },
      optionsThumbnails: {
        type: 'slide',
        pagination: false,
        arrows: true,
        fixedWidth: '11.25rem',
        fixedHeight: '7rem',
        gap: '0.188rem',
        focus: 'center',
        isNavigation: true
      }
    }
  },
  mounted() {
    this.$refs.thumbnails.sync(this.$refs.carousel.splide)
  },
  methods: {
    changeSlide(Slide, slide) {
      Slide.go(slide.index)
    }
  }
}
