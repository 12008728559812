
export default {
  name: 'MoleculeIconListWithTexts',
  props: {
    dataList: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    mobileColumnNumber: {
      required: false,
      type: Number,
      default: 6
    },
    tabletColumnNumber: {
      required: false,
      type: Number,
      default: 6
    },
    columnNumber: {
      required: false,
      type: Number,
      default: 6
    }
  }
}
