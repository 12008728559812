
export default {
  name: 'MoleculeHorizontalMenu',
  props: {
    sm: {
      required: false,
      type: Boolean,
      default: false
    },
    noMarginEnds: {
      required: false,
      type: Boolean,
      default: false
    },
    tabData: {
      required: false,
      type: Array,
      default: function () {
        return []
      }
    },
    selectedOption: {
      required: false,
      type: String,
      default: ''
    }
  },
  data() {
    let defaultTab = ''
    if (this.selectedOption !== '') {
      defaultTab = this.selectedOption
    } else if (this.$props.tabData.length) {
      defaultTab = this.$props.tabData[0].tabId
    }

    return {
      selectedTab: defaultTab
    }
  },
  watch: {
    selectedOption(newVal) {
      let defaultTab = ''
      if (this.selectedOption !== '') {
        defaultTab = this.selectedOption
      } else if (this.$props.tabData.length) {
        defaultTab = this.$props.tabData[0].tabId
      }
      this.selectedTab = defaultTab
    }
  },
  methods: {
    handleTabClick(tabId) {
      this.selectedTab = tabId
      this.$emit('tab-change', tabId)
    }
  }
}
