
export default {
  name: 'MoleculeImageThumbSlider',
  props: {
    thumbnails: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      thumbImages: [],
      activeThumb: 0
    }
  },
  watch: {
    thumbnails: {
      deep: true,
      handler: function (value, old) {
        this.thumbImages = [...value]
      }
    }
  },
  mounted() {
    this.thumbImages = this.thumbnails
  },
  methods: {
    changeActiveThumb(index) {
      if (isNaN(index)) index = 0
      this.activeThumb = index
    },
    handleThumbChange(index) {
      this.changeActiveThumb(index)
      this.$emit('thumb-change', index)
    },
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
