
import projectConstants from '~/store/project/-constants'

export default {
  name: 'MoleculeFilters',
  data() {
    return {
      showFilters: true,
      activeFilters: [],
      buildingOptions: [],
      floorOptions: [],
      surfaceOptions: [],
      // noRoomsOptions: [],
      spaceTypeOptions: [],
      availabilityOptions: [],
      orientationOptions: [],
      filteredSpaces: [],
      selectedAmenities: [],
      surfaceRange: [0, 0],
      priceRange: [0, 0],
      sliderSurfaceValues: [0, 0],
      sliderPriceValues: [0, 0]
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    activeProjectSlug() {
      return this.$store.state.project.activeProject
    },
    currentProject() {
      return this.$store.state.base.landlordDetails?.projects.find((item) => {
        return item.slug === this.activeProjectSlug
      })
    },
    buildingConfig() {
      return this.generalConfig?.expModelDisplaySettings[this.activeProjectSlug]?.building
    },
    buildingFocusConfig() {
      return this.buildingConfig?.buildingFocusConfig || []
    },
    buildings() {
      return (
        this.$store.state.base.landlordDetails?.buildings?.filter((bl) => {
          return bl.project_id === this.currentProject?.id
        }) || []
      )
    },
    floors() {
      return (
        this.$store.state.base.landlordDetails?.floors?.filter((fl) => {
          return this.buildings.some((bl) => bl.id === fl.building_id)
        }) || []
      )
    },
    spaces() {
      return (
        this.$store.state.base.landlordDetails?.spaces?.filter((sp) => {
          return sp.project_id === this.currentProject?.id
        }) || []
      )
    },
    filters() {
      return this.$store.state.project.filters
    },
    computedAmenities() {
      const amenitiesSet = {}
      const allAmenities = this.$store.state.base.landlordDetails.amenities

      this.spaces?.forEach((sp) => {
        sp.amenities.forEach((am) => {
          amenitiesSet[am.id] = this.$tdb(
            allAmenities.find((allAm) => allAm.id === am.id)?.translation.label || {}
          )
        })
      })
      return Object.entries(amenitiesSet).map(([uuid, textValue]) => ({
        uuid,
        textValue
      }))
    },
    currency() {
      return this.$store.state?.base?.meta?.general?.baseCurrencySymbol || '€'
    },
    unitOfMeasureString() {
      return this.$store.state?.base?.meta?.general?.unitOfMeasure === 1 ? 'sqft' : 'sqm'
    },
    activeFilterslen() {
      let count = 0
      for (const type in this.filters) {
        const val = this.filters[type]
        if (
          ![
            'showSoldApartments',
            'showOnlySoldApartments',
            'building',
            'floors',
            // 'noRooms',
            'spaceType',
            'orientation',
            'surfaceRange',
            'priceRange',
            'amenities'
          ].includes(type)
        ) {
          continue
        }
        // if (type === 'noRooms') {
        //   if (val && val.id !== -1) {
        //     count++
        //   }
        // } else if (type === 'priceRange') {
        if (type === 'priceRange') {
          if (
            val &&
            val.length &&
            (val[0] !== this.priceRange[0] || val[1] !== this.priceRange[1])
          ) {
            count++
          }
        } else if (type === 'surfaceRange') {
          if (
            val &&
            val.length &&
            (val[0] !== this.surfaceRange[0] || val[1] !== this.surfaceRange[1])
          ) {
            count++
          }
        } else if (type === 'orientation') {
          if (val && val !== 'all') {
            count++
          }
        } else if (type === 'showSoldApartments') {
          if (val) {
            count++
          }
        } else if (type === 'showOnlySoldApartments') {
          if (val) {
            count++
          }
        } else if (
          Array.isArray(val) &&
          !['priceRangeUniverse', 'surfaceRangeUniverse'].includes(type)
        ) {
          if (val.length > 0) {
            count++
          }
        } else if (val !== -1) {
          count++
        }
      }
      return count
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    locale() {
      return this.$i18n?.locale || 'en'
    },
    spaceTypes() {
      const existingSpaceTypesSet = new Set(
        this.spaces.map((space) =>
          space.available &&
          typeof space.space_type_id !== 'undefined' &&
          space.space_type_id !== null
            ? space.space_type_id
            : -1
        )
      )
      return (
        this.$store.state.base.landlordDetails?.spaceTypes.filter((spaceType) =>
          Array.from(existingSpaceTypesSet).includes(spaceType.id)
        ) || []
      )
    },
    spaceTypeSelectValue() {
      return this.spaceTypeOptions.find((s) => s.id === this.filters.spaceType)
    },
    filterSoldSpacesEnabled() {
      return (
        this.$store.getters.featuresForProject(this.activeProjectSlug)?.filterSoldSpaces || false
      )
    }
  },
  watch: {
    '$i18n.locale'() {
      this.initFilterOptions()
    },
    activeProjectSlug() {
      this.initFilterOptions()
    },
    buildings() {
      this.initFilterOptions()
    },
    spaces: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.setSurfaceAndPriceOptions()
        this.filterSpaces()
        this.$emit('filtered-spaces', val)
      }
    },
    '$store.state.project.filters': {
      deep: true,
      handler: function () {
        this.filterSpaces()
      }
    },
    sliderSurfaceValues() {
      this.handleFilterChange(this.sliderSurfaceValues, 'surfaceRange')
    },
    sliderPriceValues() {
      this.handleFilterChange(this.sliderPriceValues, 'priceRange')
    },
    spaceTypeSelectValue: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (typeof this.spaceTypeSelectValue === 'undefined') {
          this.$store.dispatch(
            projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE),
            {
              value: -1,
              type: 'spaceType'
            }
          )
        }
      }
    },
    'filters.showOnlySoldApartments'(newValue, oldValue) {
      console.log('change change', newValue, false)
      const manager = this.engine3d.getClientManager()
      manager.toggleResiHighlights(newValue, false)
    }
  },
  mounted() {
    this.initFilterOptions()
  },
  methods: {
    getBuildingFromId(id = -1) {
      if (id === -1) return
      return this.buildings.find((b) => b.id === id)
    },
    betweenRange(x, min, max) {
      return x >= min && x <= max
    },
    toggleShowFilters() {
      this.showFilters = !this.showFilters
    },
    initFilterOptions() {
      this.setBuildingOptions()
      this.setFloorOptions()
      this.setAvailabilityOptions()
      this.setOrientationOptions()
      // this.setNoRoomsOptions()
      this.setSpaceTypeOptions()
    },
    setBuildingOptions() {
      const buildingOptions = [{ text: this.$t('all'), id: -1 }]
      this.buildings.forEach((b, i) => {
        buildingOptions.push({ text: b.name, id: b.id })
      })
      buildingOptions.sort(function (a, b) {
        if (a.id < b.id) {
          return -1
        }
        if (a.id > b.id) {
          return 1
        }
        return 0
      })
      this.buildingOptions = buildingOptions
    },
    setFloorOptions() {
      const floors = []
      const floorOptions = []
      this.floors.forEach((f, i) => {
        if (!floors.includes(f.no)) {
          floorOptions.push({
            text: this.$t('floor', { floor: f.no }),
            value: f.no
          })
          floors.push(f.no)
        }
      })
      floorOptions.sort(function (a, b) {
        if (a.value < b.value) {
          return -1
        }
        if (a.value > b.value) {
          return 1
        }
        return 0
      })
      this.floorOptions = floorOptions
    },
    // setNoRoomsOptions() {
    //   const noRooms = []
    //   const noRoomsOptions = [
    //     { text: this.$t('all'), id: -1, numberOfRooms: -1, spaceType: 'Standard' }
    //   ]
    //   const roomTypeMap = new Map()
    //   this.spaces.forEach((s, i) => {
    //     if (!noRooms.includes(s.number_of_rooms)) {
    //       roomTypeMap.set(s.number_of_rooms, new Set())
    //     }
    //     roomTypeMap.get(s.number_of_rooms).add(s.space_type)
    //     noRooms.push(s.number_of_rooms)
    //   })
    //
    //   roomTypeMap.forEach((spaceTypes, numRooms) => {
    //     spaceTypes.forEach((spaceType) => {
    //       noRoomsOptions.push({
    //         text:
    //           spaceType === 'Standard'
    //             ? this.$tc('rooms', numRooms)
    //             : this.$tc(spaceType, spaceType),
    //         id: spaceType === 'Standard' ? `${spaceType}-${numRooms}` : `${spaceType}`,
    //         numberOfRooms: numRooms,
    //         spaceType
    //       })
    //     })
    //   })
    //   noRoomsOptions.sort(function (a, b) {
    //     if (a.numberOfRooms < b.numberOfRooms) {
    //       return -1
    //     }
    //     if (a.numberOfRooms > b.numberOfRooms) {
    //       return 1
    //     }
    //     return 0
    //   })
    //
    //   this.noRoomsOptions = noRoomsOptions
    // },
    setSpaceTypeOptions() {
      const result = this.spaceTypes.map((spaceType) => {
        const translation = spaceType.space_type_translations.find((t) => t.lang === this.locale)
        return {
          text: translation?.name || spaceType.name,
          id: spaceType.id
        }
      })
      this.spaceTypeOptions = [{ text: this.$t('all'), id: -1 }, ...result]
    },
    setSurfaceAndPriceOptions() {
      this.surfaceRange = this.initSurfaceRange()

      const surfaceSliderValue = [...this.surfaceRange]
      if (this.filters.surfaceRange && this.filters.surfaceRange.length) {
        if (
          this.filters.surfaceRange[0] !== 0 &&
          this.filters.surfaceRange[0] > this.surfaceRange[0]
        ) {
          surfaceSliderValue[0] = this.filters.surfaceRange[0]
        }

        if (
          this.filters.surfaceRange[1] !== 0 &&
          this.filters.surfaceRange[1] < this.surfaceRange[1]
        ) {
          surfaceSliderValue[1] = this.filters.surfaceRange[1]
        }
      }
      this.sliderSurfaceValues = surfaceSliderValue

      this.priceRange = this.initPriceRange()
      const priceSliderValue = [...this.priceRange]
      if (this.filters.priceRange && this.filters.priceRange.length) {
        if (this.filters.priceRange[0] !== 0 && this.filters.priceRange > this.priceRange[0]) {
          priceSliderValue[0] = this.filters.priceRange[0]
        }

        if (this.filters.priceRange[1] !== 0 && this.filters.priceRange[1] < this.priceRange[1]) {
          priceSliderValue[1] = this.filters.priceRange[1]
        }
      }
      this.sliderPriceValues = priceSliderValue
    },
    setAvailabilityOptions() {
      this.availabilityOptions = [
        { text: 'Any', value: '' },
        { text: 'On sale', value: true },
        { text: 'Sold', value: false }
      ]
    },
    setOrientationOptions() {
      const orientation = []
      const orientationOptions = []
      this.spaces.forEach((s, i) => {
        if (!orientation.includes(s.orientation)) {
          orientationOptions.push({
            text: this.$t(`${s.orientation}`),
            id: s.orientation
          })
          orientation.push(s.orientation)
        }
      })

      orientationOptions.sort(function (a, b) {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })
      orientationOptions.unshift({ text: this.$t('all'), id: 'all' })

      this.orientationOptions = orientationOptions
    },
    handleFilterChange(selectedItem, type, value) {
      let selectedValue = selectedItem
      if (value) {
        selectedValue = value
      }
      // if (type === 'noRooms') {
      //   selectedValue = selectedItem
      // }
      this.$store.dispatch(
        projectConstants.withNamespace(projectConstants.action.CHANGE_FILTER_STATE),
        {
          value: selectedValue,
          type
        }
      )

      if (type === 'building') {
        if (this.buildingFocusConfig && this.buildingFocusConfig.length > 0) {
          const building = this.getBuildingFromId(value)
          let target = [
            this.buildingConfig.target.x,
            this.buildingConfig.target.y,
            this.buildingConfig.target.z
          ]
          if (building) {
            const focusConfig = this.buildingFocusConfig.find((c) => c.code === building.code)
            if (focusConfig) {
              target = focusConfig.target
            }
          }

          const manager = this.engine3d.getClientManager()
          manager.setCamera3DViewTarget(target)
        }
      }
    },
    filterSpaces() {
      let filteredSpaces = [...this.spaces]
      if (!this.filters.showSoldApartments && !this.filters.showOnlySoldApartments) {
        filteredSpaces = filteredSpaces.filter((sp) => sp.available)
      }
      if (this.filters.showOnlySoldApartments) {
        filteredSpaces = filteredSpaces.filter((sp) => !sp.available)
      }
      if (this.filters.building !== -1) {
        filteredSpaces = filteredSpaces.filter(
          (s) => Number(s.building_id) === Number(this.filters.building)
        )
      }
      if (this.filters.floors.length && !this.filters.floors.includes('')) {
        filteredSpaces = filteredSpaces.filter((s) =>
          this.filters.floors.includes(Number(s.floor.no))
        )
      }
      // if (this.filters.noRooms.id !== -1) {
      //   filteredSpaces = filteredSpaces.filter(
      //     (s) =>
      //       Number(s.number_of_rooms) === Number(this.filters.noRooms.numberOfRooms) &&
      //       s.space_type === this.filters.noRooms.spaceType
      //   )
      // }
      if (this.filters.spaceType !== -1) {
        filteredSpaces = filteredSpaces.filter((s) => s.space_type_id === this.filters.spaceType)
      }
      if (this.filters.orientation !== 'all') {
        filteredSpaces = filteredSpaces.filter((s) => s.orientation === this.filters.orientation)
      }
      if (this.filters.surfaceRange.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          if (
            this.betweenRange(
              s.util_sqm,
              this.filters.surfaceRange[0],
              this.filters.surfaceRange[1]
            )
          ) {
            return s
          }
          return false
        })
      }
      if (this.filters.priceRange.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          if (this.betweenRange(s.price, this.filters.priceRange[0], this.filters.priceRange[1])) {
            return s
          }
          return false
        })
      }
      if (this.filters.amenities?.length > 0) {
        filteredSpaces = filteredSpaces.filter((s) => {
          const result = s.amenities
            .map((am) => am.id)
            .filter((am) => this.filters.amenities.includes(am.toString()))
          return result.length > 0
        })
      }

      this.filteredSpaces = filteredSpaces
      this.$emit('filtered-spaces', filteredSpaces)
    },
    handleClearAllFilters() {
      this.$store.dispatch(projectConstants.withNamespace(projectConstants.action.RESET_FILTERS))
      this.initFilterOptions()
      this.setSurfaceAndPriceOptions()
    },
    initSurfaceRange() {
      if (this.spaces?.length === 0) return [0, 0]
      let minSurface = this.spaces?.[0]?.util_sqm ?? 0
      let maxSurface = this.spaces?.[0]?.util_sqm ?? 0
      this.spaces.forEach((s, i) => {
        // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
        // const balconySurface =
        //   s.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
        //     ?.surface || 0
        // const gardenSurface =
        //   s.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())?.surface ||
        //   0
        // const minUtilSurface = s.util_sqm + balconySurface + gardenSurface
        // const maxUtilSurface = s.util_sqm + balconySurface + gardenSurface
        const minUtilSurface = s?.util_sqm ?? 0
        const maxUtilSurface = s?.util_sqm ?? 0
        if (minUtilSurface < minSurface) {
          minSurface = minUtilSurface
        } else if (maxUtilSurface > maxSurface) {
          maxSurface = maxUtilSurface
        }
      })
      return [Math.floor(minSurface), Math.ceil(maxSurface)]
    },
    initPriceRange() {
      if (this.spaces?.length === 0) return [0, 0]
      let minPrice = this.spaces[0].price
      let maxPrice = this.spaces[0].price
      this.spaces.forEach((s, i) => {
        if (s.price < minPrice) {
          minPrice = s.price
        } else if (s.price > maxPrice) {
          maxPrice = s.price
        }
      })

      return [Math.floor(minPrice), Math.ceil(maxPrice)]
    }
  }
}
