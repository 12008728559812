
export default {
  name: 'MoleculeMultipleOptions',
  props: {
    title: {
      required: true,
      type: String,
      default: 'Title'
    },
    options: {
      required: true,
      type: Array,
      default: () => []
    },
    value: {
      required: true,
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      lazyValue: this.value
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(val) {
        this.lazyValue = val
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value(val) {
      this.lazyValue = val
    }
  },
  methods: {
    handleClearAll() {
      this.internalValue = []
    },
    handleClick(val, opt) {
      let newValue = [...this.lazyValue]
      if (val) {
        newValue.push(opt)
      } else {
        newValue = newValue.filter((v) => v !== opt)
      }

      this.internalValue = newValue
    }
  }
}
