
export default {
  name: 'MoleculeContentSlider',
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      instance: null
    }
  },
  mounted() {
    this.initCarousel()
  },
  beforeUpdate() {
    this.destroyCarousel()
  },
  updated() {
    this.initCarousel()
  },
  methods: {
    initCarousel() {
      this.instance = window.$(this.$refs.carousel).owlCarousel({
        items: 1,
        nav: true,
        dots: true,
        margin: 20,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        ...this.carouselOpts
      })
    },
    destroyCarousel() {
      this.instance.trigger('destroy.owl.carousel')
      this.instance = null
    }
  }
}
