import { render, staticRenderFns } from "./MoleculeVideoSliderWithThumbnails.vue?vue&type=template&id=595a94c9&"
import script from "./MoleculeVideoSliderWithThumbnails.vue?vue&type=script&lang=js&"
export * from "./MoleculeVideoSliderWithThumbnails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonSliderAtomVideoSlider: require('/app/components/atoms/common/slider/AtomVideoSlider.vue').default,AtomsCommonSliderAtomImageThumbSlider: require('/app/components/atoms/common/slider/AtomImageThumbSlider.vue').default})
