
import { hashTextInSHA256 } from '~/helpers/util'
import { WebRequest } from '~/helpers/api'

export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}
export default {
  name: 'MoleculeContactForm',
  data() {
    return {
      errors: {},
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      message: '',
      inputTypes: EnumInputTypes
    }
  },
  computed: {
    client() {
      return this.$config.CLIENT
    }
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      if (this.validateInputs() !== null) return

      const activeProject = this.$store.getters.getActiveProjectData

      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'contact_request_submit',
          category: 'contact_request',
          label: 'contact_request'
        })
      }

      const payload = {
        deal_name: `${this.first_name} ${this.last_name}`,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
        email: this.email,
        notes: this.message,
        project_id: activeProject.id,
        status: 0
      }

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'contact_request_submit',
          payload
        })
      }

      WebRequest.POST(this.$webApi.landlord.contact(), payload)
        .then((res) => {
          if (res.error) {
            alert(res.error.message)
            return
          }
          this.$refs.contactForm.reset()
          alert('Message sent!')
          this.$refs.check.checked = false
          this.convertMetaContactEvent()
        })
        .catch((e) => {
          alert(e.message)
        })
    },

    validateInputs() {
      this.errors = {}
      if (this.first_name === '') {
        this.errors.first_name = this.$t('First Name is required.')
      }
      if (this.last_name === '') {
        this.errors.last_name = this.$t('Last Name is required.')
      }
      if (this.phone_number === '' || !this.phone_number.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone_number = this.$t('Phone is required.')
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = this.$t('Email is not valid.')
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = this.$t('Please agree to the Terms and Conditions first.')
      }
      if (Object.keys(this.errors).length === 0) {
        return null
      }
      return this.errors
    },

    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    async convertMetaContactEvent() {
      if (!window || !('fbq' in window)) return
      const date = new Date()
      const unixTimestampInSeconds = Math.floor(date.getTime() / 1000)
      const data = [
        {
          event_name: 'Contact',
          event_time: unixTimestampInSeconds,
          action_source: 'website',
          event_source_url: window.location.href,
          user_data: {
            em: await hashTextInSHA256(this.email),
            ph: await hashTextInSHA256(this.phone_number)
          }
        }
      ]
      window.fbq('track', 'Contact', data)
      // console.log("FBQ CONTACT", data);
    }
  }
}
