
export default {
  name: 'MoleculeImageSlider',

  props: {
    sliderData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    typeOf: {
      required: true,
      type: String,
      default: function () {
        return 'image'
      }
    }
  },
  data() {
    return {
      instance: null,
      options: {
        perPage: 1,
        type: 'loop',
        pagination: false
      }
    }
  },
  mounted() {
    // this.initCarousel()
  },
  beforeUpdate() {
    // this.destroyCarousel()
  },
  updated() {
    // this.initCarousel()
  },
  methods: {
    // initCarousel() {
    //   if (process.browser) {
    //     console.log(window)
    //     const carouselOpts = {
    //       items: 1,
    //       nav: true,
    //       margin: 20,
    //       dotsContainer: '#owl-custom-dots'
    //     }
    //     // this.instance = window.$(this.$refs.carousel).owlCarousel(carouselOpts)
    //     // this.instance.on('change.owl.carousel', (data) => {
    //     //   this.$parent.$refs.thumbSlider.changeActiveThumb(data.property.value)
    //     // })
    //     this.$emit('initialized', true)
    //   }
    // },
    // destroyCarousel() {
    //   if (!this.instance) return
    //   this.instance.trigger('destroy.owl.carousel')
    //   this.instance.off('change.owl.carousel')
    //   this.instance = null
    //   this.$emit('initialized', false)
    // },
    // refreshCarousel() {
    //   this.instance.trigger('refresh.owl.carousel')
    // }
  }
}
